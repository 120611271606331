<template>
  <v-container class="mt-5 pa-6 pa-lg-4 pr-lg-6">
    <v-text-field
      v-model="username"
      prepend-icon="mdi-account-circle"
      label="Usuario o Correo"
      :rules="[rules.required]"
      filled
    />
    <v-text-field
      v-model="password"
      prepend-icon="mdi-lock"
      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required]"
      :type="show1 ? 'text' : 'password'"
      name="input-10-1"
      label="Password"
      hint=""
      counter
      filled
      @click:append="show1 = !show1"
    />
    <v-alert
      v-if="loginerror"
      dense
      outlined
      type="error"
    >
      Usuario y/o contraseña incorrectos.
    </v-alert>
    <v-alert
      v-if="accesserror"
      dense
      outlined
      type="error"
    >
      Acceso no autorizado, contacte a un administrador.
    </v-alert>
    <v-alert
      v-if="usererror"
      dense
      outlined
      type="error"
    >
      Acceso ha fallado, contacte a un administrador.
    </v-alert>
    <v-checkbox
      class="mt-0 ml-4 mb-0"
      label="Mantener sesión iniciada"
    />
    <v-btn
      color="primary"
      class="mb-4"
      block
      @click.prevent="authenticate"
      :loading="loginloading"
    >
      Login
    </v-btn>
    <router-link to="/login/restorepassword/">
      ¿Ha olvidado su contraseña?
    </router-link>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import Vuex from 'vuex'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  Vue.use(Vuex)
  Vue.use(VueAxios, axios)

  Vue.axios.defaults.baseURL = process.env.VUE_APP_HOST

  export default {
    data () {
      return {
        show1: false,
        error: false,
        loginerror: false,
        accesserror: false,
        usererror: false,
        loginloading: false,
        username: '',
        password: '',
        rules: {
          required: (value) => !!value || 'Required.',
          min: (v) => v.length >= 8 || 'Min 8 characters',
        },
      }
    },

    methods: {
      authenticate () {
        this.error = false // not a real use now but it might be helpul for raising a flag
        this.loginerror  = false // only for login
        this.accesserror = false // only for users that exist but are not allowed to access
        this.usererror   = false // only when currentuser call fails

	this.loginloading = true // spinner

        const payload = {
          username: this.username,
          password: this.password,
        }

        Vue.axios.post('auth/obtain_token/', payload)
          .then((response) => {
            if (response.status === 200) {
              this.$store.commit('updateToken', response.data.token)

              // PENDIENTE AL 16-10
              // AGREGAR SANITY CHECKS EN CADA PETICION A LA API REST DJANGO
              // SI SE FALLA EN ALGÚN PASO, LOGIN NO PUEDE CONTINUAR. SE DEBE MOSTRAR UN MENSAJE DE ERROR ACORDE AL PASO

              // 09-08-2023: PORQUE NO CONTINUAR SI FALLA ALGUN PASO ? SE IMPLEMENTA LOGINERROR PARA AISLAR 
	      // MENSAJE EN CASO EN QUE FALLAN CREDENCIALES O RESPONSE STATUS. DIRIA QUE SI FALLA UN PASO 
	      // INTERMEDIO (PUENTES,RIOS) EL USUARIO DEBERIA PODER PASAR AL DASHBOARD IGUAL. 

              Vue.axios.get('currentuser/')
                .then((response) => {
                  //console.log(response.data)
                  this.$store.commit(
                    'setAuthUser',
                    { authUser: response.data, isAuthenticated: true },
                  )

                  // Ahora se buscan todos los datos asociados al usuario
                  // Si tiene grupo, es parte de Riverine, por lo tanto, le mostramos todos los puentes
                  // Si no, es solo un usuario empresa, así que solo le mostramos los puentes asociados a su empresa respectiva
		  // 09-08-2023: groups viene como una lista con los nombres del grupo, algo se puede hacer con eso.
		  // ej: [{"name":"Moderators"},]
                  var userGroup = response.data.groups
                  if (userGroup.length > 0) {
                    //console.log('USUARIO TIENE GRUPO')
                    //console.log(userGroup)
                    // Como es usuario riverine, empresa se define como false
                    this.$store.commit('setUserEmpresa', false)
                    this.$store.commit('setIsModerator', true )

                    // Pedimos todos los puentes y rios
                    Vue.axios.get('puentes/')
                      .then((response) => {
                        console.log(response.data)

                        var thisPuentes = response.data
                        var thisMarkers = []

                        for (let i = 0; i < thisPuentes.length; i++) {
                          thisPuentes[i].status = 4
                          var aux = {
                            id: thisPuentes[i]['id'],
                            position: {lat: thisPuentes[i]['latitud'], lng: thisPuentes[i]['longitud']},
                            status: thisPuentes[i].status
                          }
                          thisMarkers.push(aux);

                          // Para cada puente, se solicita el rio respectivo
                          Vue.axios.get('getrio/' + thisPuentes[i].rio + '/')
                            .then((response) => {
                              //console.log(response.data)
                              thisPuentes[i].rio = response.data
                            })
                            .catch((error) => {
                              this.error = true
                              throw new Error(`API ${error}`)
                            })
                          // Para cada puente, se solicita los sensores respectivos
                          Vue.axios.get('getsensorespuente/' + thisPuentes[i].id+ '/')
                            .then((response) => {
                              //console.log('SETTING SENSORES')
                              //console.log(response.data)
                              var this_sensores = response.data.sensores;
                              for(var j = 0; j < this_sensores.length; j++){
                                this_sensores[j].last_value = false;
                                this_sensores[j].last_value_dt = Date.now();
                              }
                              thisPuentes[i].sensores = this_sensores;
                            })
                            .catch((error) => {
                              this.error = true
                              throw new Error(`API ${error}`)
                            })
                        }
                        //console.log(thisPuentes)
                        this.$store.commit(
                          'setPuentesList',
                          thisPuentes,
                        );
                        this.$store.commit(
                          'setMarkersList',
                          thisMarkers,
                        );
			// To dashboard
                        this.$router.push('/dashboard/map')
                      })
                      .catch((error) => {
                        this.error = true
                        throw new Error(`API ${error}`)
                      })
                  } else {
                    //console.log('USUARIO NO TIENE GRUPO')

                    this.$store.commit('setIsModerator', false )

                    Vue.axios.get('getusuarioempresa/' + response.data.id + '/')
                      .then((response) => {
                        //console.log(response.data)

                        // Se define empresa de usuario
                        this.$store.commit('setUserEmpresa', response.data.perfil_usuario)

                        var thisPuentes = response.data.puentes
                        var thisMarkers = []

                        for (let i = 0; i < thisPuentes.length; i++) {
                          thisPuentes[i].status = 4
                          var aux = {
                            id: thisPuentes[i]['id'],
                            position: {lat: thisPuentes[i]['latitud'], lng: thisPuentes[i]['longitud']},
                            status: thisPuentes[i].status
                          }
                          thisMarkers.push(aux);

                          // Para cada puente, se solicita el rio respectivo
                          Vue.axios.get('getrio/' + thisPuentes[i].rio + '/')
                            .then((response) => {
                              //console.log(response.data)
                              thisPuentes[i].rio = response.data
                            })
                            .catch((error) => {
                              this.error = true
                              throw new Error(`API ${error}`)
                            })
                          // Para cada puente, se solicita los sensores respectivos
                          Vue.axios.get('getsensorespuente/' + thisPuentes[i].id+ '/')
                            .then((response) => {
                              //console.log('SETTING SENSORES')
                              //console.log(response.data)
                              var this_sensores = response.data.sensores;
                              for(var j = 0; j < this_sensores.length; j++){
                                this_sensores[j].last_value = false;
                                this_sensores[j].last_value_dt = Date.now();
                              }
                              thisPuentes[i].sensores = this_sensores;
                            })
                            .catch((error) => {
                              this.error = true
                              throw new Error(`API ${error}`)
                            })
                        }
                        //console.log(thisPuentes)
                        this.$store.commit(
                          'setPuentesList',
                          thisPuentes,
                        );
                        this.$store.commit(
                          'setMarkersList',
                          thisMarkers,
                        );
			// To dashboard
                        this.$router.push('/dashboard/map')
                      })
                      .catch((error) => {
                        this.error = true
                        this.accesserror = true
	                this.loginloading = false
                        throw new Error(`API ${error}`)
                      })
                  }
                })
                .catch((error) => {
                  this.error = true
                  this.usererror = true
	          this.loginloading = false
                  throw new Error(`API ${error}`)
                })
            } else {
              this.error = true
              this.loginerror = true
	      this.loginloading = false
            }
          })
          .catch((error) => {
	    this.loginloading = false
            this.loginerror = true
            this.error = true
            throw new Error(`API ${error}`)
          })
      },
    },
  }
</script>

<style>

</style>
